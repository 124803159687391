/**
 * PostCard
 * Tooltips are decided as follows:
 * 1. If the user is new (teaser)
 * 2. If the post needs support (teaser)
 * 3. If the user hasn't dismissed the collections tooltip (post view)
 */

import { mdiMapMarker, mdiPound, mdiCellphoneText } from '@mdi/js';
import Icon from '@mdi/react';
import dynamic from 'next/dynamic';
import dompurify from 'isomorphic-dompurify';
import 'linkifyjs';
import Linkify from 'linkify-react';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { getUrlType, postUrl, truncateString } from '@/lib/utils';
import extractUrls from '@/lib/extractUrls';
import ModalLink from '../General/ModalLink';
import PollCard from './PollCard';
import Avatar from '../Profile/Avatar';
import PostCardUrlPreview from './media/PostCardUrlPreview';
import PostCardVideo from './media/PostCardVideo';
import ShiftmsUrlPreview from './media/ShiftmsUrlPreview';
import PostCardComments from './PostCardComments';
import PostCardReaction from './PostCardReaction';
import PostCardCollections from './PostCardCollections';
import PostOptions from './PostOptions';
import { IActivityPost, IPost } from '@/types/Collections/posts';
import { ICollection } from '@/types/Collections/collections';
import PostCardAuthor from './PostCardAuthor';

const DateTimeFormatted = dynamic(
  () => import('../General/DateTimeFormatted'),
  {
    ssr: false,
  },
);

// hashtag(linkify);
// mention(linkify);

const PostCard = ({
  post,
  expanded,
  collection,
  isSpace = false,
  activityType = null,
}: {
  post: IPost | IActivityPost;
  expanded?: boolean;
  collection?: ICollection;
  isSpace?: boolean;
  activityType?: string | null;
}): JSX.Element => {
  const router = useRouter();

  const route = useMemo(() => {
    if (router.route) {
      const routeName = router.route.split('/');
      if (routeName.length > 1) {
        return routeName[1];
      }
    }

    return null;
  }, [router.route]);

  const [urlPreview, setUrlPreview] = useState<string | null>(null);
  const [urlType, setUrlType] = useState('default');

  useEffect(() => {
    const urls = extractUrls(post.content);
    if (urls && urls.length) {
      const [url] = urls;
      setUrlType(getUrlType(url));
      setUrlPreview(url);
    }
  }, [post.content]);

  const theContent = (content: string) => {
    if (expanded) {
      return content;
    }
    return truncateString(content, 300);
  };

  const purifiedContent = dompurify.sanitize(theContent(post.content), {
    ADD_ATTR: ['target'],
  });

  const categories = () => {
    if (post.categories && post.categories.length) {
      return (
        <ul>
          {post.categories.map((cat: string) => (
            <li key={`${post.id}-cat-${cat}`}>
              <Link
                legacyBehavior
                href={`/hashtag?query=${cat.toLowerCase().replace(/ /g, '-')}`}
                as={`/hashtag/${cat.toLowerCase().replace(/ /g, '-')}`}>
                <a
                  target={route === 'embedPost' ? '_top' : undefined}
                  className="tag is-medium is-rounded">
                  {cat}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const heroEmbed = () => {
    if (urlPreview) {
      switch (urlType) {
        case 'youtube':
          return <PostCardVideo url={urlPreview} />;

        case 'shiftms':
          return <ShiftmsUrlPreview url={urlPreview} />;

        case 'default':
        default:
          return null;
      }
    }

    if (post.image) {
      return (
        <ModalLink
          as={postUrl(post)}
          params={{
            postId: post.id,
            collectionId: collection ? collection.id : undefined,
            username: post.author.username,
          }}>
          <a target={route === 'embedPost' ? '_top' : undefined}>
            <span
              className="shiftms-url-image image is-16by9"
              style={{
                backgroundImage: `url(${post.image})`,
              }}
            />
          </a>
        </ModalLink>
      );
    }
    return null;
  };

  let username = 'user';
  if (activityType && activityType === 'comment') {
    if (router.query.username) {
      username = `@${router.query.username}`;
    } else if (router.query.slug && Array.isArray(router.query.slug)) {
      [username] = router.query.slug;
    }
  }
  return (
    <div
      id={`postcard-${post.id}`}
      className={`card postcard${isSpace ? ' is-space' : ''}${
        urlType === 'youtube' || urlType === 'shiftms' ? ' has-preview' : ''
      }`}>
      {heroEmbed()}
      {activityType === 'comment' && (
        <div className="postcard-reason">
          <div className="postcard-reason-content">
            <ModalLink
              as={username}
              params={{
                username,
              }}>
              <a target={route === 'embedPost' ? '_top' : undefined}>
                {username}
              </a>
            </ModalLink>{' '}
            commented on{' '}
            <ModalLink
              as={`/@${post.author.username}`}
              params={{
                username: post.author.username,
              }}>
              <a target={route === 'embedPost' ? '_top' : undefined}>
                @{post.author.username}
              </a>
            </ModalLink>{' '}
            post
          </div>
        </div>
      )}
      <div className="card-content">
        {post.isScheduled ||
        post.isDeleted ||
        !post.isPublished ||
        post.isLocked ? (
          <div className="tags are-medium">
            {post.isScheduled ? (
              <span className="tag is-danger">Scheduled</span>
            ) : (
              ''
            )}
            {post.isDeleted ? (
              <span className="tag is-danger">Deleted</span>
            ) : (
              ''
            )}
            {!post.isPublished && !post.isScheduled ? (
              <span className="tag is-danger">Unpublished</span>
            ) : (
              ''
            )}
            {post.isLocked ? <span className="tag is-danger">Locked</span> : ''}
          </div>
        ) : (
          ''
        )}
        <div className="media postcard-author">
          <div className="media-content">
            <PostCardAuthor author={post.author} route={route} />
            <p className="is-font-brand">
              <DateTimeFormatted timestamp={post.created} />
              {post.edited ? (
                <>
                  {' '}
                  <span className="is-font-normal tag is-info is-light">
                    Edited
                  </span>
                </>
              ) : (
                ''
              )}
              {post.isScheduled ? (
                <span className="postcard-date-last-active">
                  Scheduled for{' '}
                  <DateTimeFormatted timestamp={post.scheduleDate} />
                </span>
              ) : (
                ''
              )}
              {post.commentsCount > 0 ? (
                <span className="postcard-date-last-active">
                  Last reply <DateTimeFormatted timestamp={post.lastActive} />
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="media-right">
            <ModalLink
              as={`/@${post.author.username}`}
              params={{
                username: post.author.username,
              }}>
              <a
                target={route === 'embedPost' ? '_top' : undefined}
                className="avatar-link">
                <Avatar
                  imageUrl={post.author.profileImgUrl}
                  username={post.author.username}
                  linkIt={false}
                  size={64}
                />
              </a>
            </ModalLink>
          </div>
        </div>
        <div className="postcard-body">
          <div className="postcard-body-content">
            <div className="postcard-content">
              <div className="content">
                <h2 className="title">
                  <ModalLink
                    as={postUrl(post)}
                    params={{
                      postId: post.id,
                      username: post.author.username,
                      collectionId: collection ? collection.id : undefined,
                    }}>
                    <a target={route === 'embedPost' ? '_top' : undefined}>
                      {post.title}
                    </a>
                  </ModalLink>
                </h2>
                <div
                  className="postcard-content-body"
                  // eslint-disable-next-line react/no-danger
                  // dangerouslySetInnerHTML={{ __html: purifiedContent }}
                >
                  <Linkify
                    options={{
                      target: route === 'embedPost' ? '_top' : undefined,
                      formatHref: {
                        hashtag: val => `/hashtag/${val.substring(1)}`,
                        mention: val => `/@${val.substring(1)}`,
                      },
                    }}>
                    {purifiedContent}
                  </Linkify>
                </div>
              </div>
              {post.poll && <PollCard expanded={!!expanded} post={post} />}
              {urlPreview && urlType === 'default' ? (
                <PostCardUrlPreview url={urlPreview} />
              ) : (
                ''
              )}
            </div>
            <div className="postcard-meta">
              {post.geoInfo && post.geoInfo.location ? (
                <div className="media postcard-location">
                  <div className="media-left">
                    <span className="icon has-text-grey-light">
                      <Icon path={mdiMapMarker} size={1.5} />
                    </span>
                  </div>
                  <div className="media-content has-text-grey">
                    {post.geoInfo.location}
                  </div>
                </div>
              ) : (
                ''
              )}
              {post.categories && post.categories.length ? (
                <div className="media postcard-categories">
                  <div className="media-left">
                    <span className="icon has-text-grey-light">
                      <Icon path={mdiPound} size={1.5} />
                    </span>
                  </div>
                  <div className="media-content has-text-grey">
                    {categories()}
                  </div>
                </div>
              ) : (
                ''
              )}
              {post.platform && post.platform !== 'web' && (
                <div className="mt-2">
                  <a
                    className="tag is-medium is-primary is-light"
                    href="https://shift.ms/app"
                    target="_blank">
                    <span className="icon has-text-primary">
                      <Icon path={mdiCellphoneText} size={1} />
                    </span>
                    <span>First posted on the Shift.ms app</span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="postcard-actions">
            <PostCardReaction post={post} isTeaser={!expanded} />
            <PostCardComments post={post} isTeaser={!expanded} />
            <PostCardCollections post={post} isTeaser={!expanded} />
            <PostOptions expanded={!!expanded} post={post} />
          </div>
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  collection: PropTypes.object,
  isSpace: PropTypes.bool,
  activityType: PropTypes.string,
};

PostCard.defaultProps = {
  expanded: false,
  collection: undefined,
  isSpace: false,
  activityType: null,
};

export default PostCard;
