import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

const squareVideos = [
  'https://youtube.com/watch?ab_channel=chrisgavin&v=_xKCwzgI68s', // test video
  'https://youtube.com/watch?v=I5ImdyYbuqI', // Individual giving
  'https://youtube.com/watch?v=I5ImdyYbuqI&feature=youtu.be',
  'https://www.youtube.com/watch?v=I5ImdyYbuqI&feature=youtu.be',
  'https://youtube.com/watch?v=Ep_fwJnC5I0', // Individual giving
  'https://youtube.com/watch?v=Ep_fwJnC5I0&feature=youtu.be',
  'https://www.youtube.com/watch?v=atCnz_VKl4g&feature=youtu.be',
  'https://youtube.com/watch?v=atCnz_VKl4g', // Tomorrow Andy
  'https://youtube.com/watch?v=atCnz_VKl4g&feature=youtu.be',
  'https://www.youtube.com/watch?v=atCnz_VKl4g&feature=youtu.be',
  'https://youtube.com/watch?v=ZWeD3qP_9j0', // Tomorrow Aleks
  'https://youtube.com/watch?v=ZWeD3qP_9j0&feature=youtu.be',
  'https://www.youtube.com/watch?v=ZWeD3qP_9j0&feature=youtu.be',
  'https://youtube.com/watch?v=V50LJeuTi4w', // Tomorrow Hayley
  'https://youtube.com/watch?v=V50LJeuTi4w&feature=youtu.be',
  'https://www.youtube.com/watch?v=V50LJeuTi4w&feature=youtu.be',
  'https://youtube.com/watch?feature=youtu.be&v=V50LJeuTi4w',
  'https://youtube.com/watch?v=uROR_h8IeMc', // Tomorrow Maytee
  'https://youtube.com/watch?v=uROR_h8IeMc&feature=youtu.be',
  'https://youtube.com/watch?feature=youtu.be&v=uROR_h8IeMc',
  'https://www.youtube.com/watch?v=uROR_h8IeMc&feature=youtu.be',
  'https://www.youtube.com/watch?v=mvZ9haizklE', // Tomorrow Spencer
  'https://www.youtube.com/watch?v=mvZ9haizklE&feature=youtu.be',
  'https://youtube.com/watch?feature=youtu.be&v=mvZ9haizklE',
];

const PostCardVideo = (props) => {
  const { url } = props;
  if (!url) {
    return null;
  }

  const classes = [
    'has-background-dark',
    'image',
    'is-16by9',
    'postcard-video',
  ];

  if (squareVideos.includes(url)) {
    classes[2] = 'is-square';
  }
  return (
    <div className={classes.join(' ')}>
      <ReactPlayer
        className="react-player"
        url={url}
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
};

PostCardVideo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PostCardVideo;
