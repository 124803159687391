import { useAuth } from '@/providers/AuthProvider';
import { IUser, MSConnection } from '@/types/Collections/users';
import { msTypesShorthand } from '@/lib/mstypes';

const AuthorMiniBio = ({
  user,
  error,
}: {
  user: IUser | null | undefined;
  error: Error;
}): JSX.Element => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn || !user || error) {
    return <span className="author-mini-bio is-font-brand">&nbsp;</span>;
  }

  const msType = () => {
    if (user.msConnection === MSConnection['I have MS'] && user.msType) {
      const out = [];
      if (user.msType && user.msType.toLowerCase() in msTypesShorthand) {
        out.push(
          msTypesShorthand[
            user.msType.toLowerCase() as keyof typeof msTypesShorthand
          ],
        );
      }
      if (user.treatment) {
        out.push(user.treatment);
      }
      return out.join(', ');
    }
    return user.msConnection;
  };

  return <span className="author-mini-bio is-font-brand">{msType()}</span>;
};

export default AuthorMiniBio;
