import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { doc, updateDoc } from 'firebase/firestore';
import * as gtag from '../../../lib/gtag';
import eventAdd from '../../../lib/eventAdd';
import { useAuth } from '../../../providers/AuthProvider';
import { db } from '../../../firebase/firebase';

const PostOptionsDelete = ({
  post, onClose, action, collection,
}) => {
  const { uid, auth } = useAuth();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const update = async (payload) => {
    try {
      if (action === 'deletePostFromCollection' && collection) {
        await updateDoc(doc(db, 'userCollections', uid, 'collections', collection.id, 'posts', post.id), payload);
      } else if (post) {
        await updateDoc(doc(db, 'posts', post.id), payload);
      }
    } catch (error) {
      // console.log(error, 'error!');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const postDeleteConfirm = async () => {
    if (action === 'deletePostFromCollection' || uid === post.author.uid) {
      const deletedDate = new Date();
      await update({
        isDeleted: true,
        deletedDate,
        modified: deletedDate,
      });
      if (action === 'deletePostFromCollection') {
        enqueueSnackbar('Post has been deleted from the collection', { variant: 'success' });
      } else {
        enqueueSnackbar('Your post has been deleted', { variant: 'success' });
      }
      router.reload();
    } else {
      enqueueSnackbar("You can't delete someone else's post", {
        variant: 'error',
      });
    }
    if (action === 'deletePostFromCollection') {
      if (post) {
        eventAdd({
          uid,
          eventType: 'COLLECTION:REMOVE_POST',
          eventSource: 'COLLECTION',
          eventSourceId: collection.id,
          meta: { },
          email: auth.email,
          platform: 'web'
        });
        gtag.event({
          action: 'collection.post.delete',
          params: {
            collectionId: collection.id,
            postId: post.id,
            title: post.title,
            uid,
          },
        });
      }
    } else if (post) {
      gtag.event({
        action: 'post.delete',
        params: {
          postId: post.id,
          title: post.title,
          uid,
        },
      });
    }
    handleClose();
  };

  const postUndeleteConfirm = async () => {
    if (uid === post.author.uid) {
      const modified = new Date();
      await update({
        isDeleted: false,
        deletedDate: null,
        restoredDate: modified,
        modified,
      });
      enqueueSnackbar('Your post has been restored', { variant: 'success' });
      router.reload();
    } else {
      enqueueSnackbar("You can't restore someone else's post", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.delete',
      params: {
        postId: post.id,
        title: post.title,
        uid,
      },
    });
    handleClose();
  };

  return (
    <div className="card confirm-dialog">
      <div className="card-content">
        <p className="mb-4 is-font-brand is-size-4">
          Are you sure you wish to
          {' '}
          { action === 'delete' ? 'delete' : action === 'deletePostFromCollection' ? 'remove' : 'restore' }
          {' '}
          this post?
        </p>
        <div className="buttons modal-footer-buttons">
          <button
            type="button"
            aria-label="Close"
            onClick={() => handleClose()}
            className="button is-light is-medium"
          >
            <span className="icon">
              <Icon path={mdiClose} size={1.25} />
            </span>
          </button>
          <button
            type="button"
            aria-label="OK"
            onClick={() => (action === 'delete' || action === 'deletePostFromCollection'
              ? postDeleteConfirm()
              : postUndeleteConfirm())}
            className="button is-danger is-medium"
          >
            <span className="icon">
              <Icon path={mdiCheck} size={1.25} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

PostOptionsDelete.propTypes = {
  collection: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.shape({
      username: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

PostOptionsDelete.defaultProps = {
  collection: undefined,
};

export default PostOptionsDelete;
