import * as React from 'react';

const SvgSupport = (props) => (
  <svg data-name="Layer 1" viewBox="0 0 33.43 30.56" {...props}>
    <path
      d="M17.1,30.6c-0.3,0-0.7-0.1-1-0.2c-1-0.3-1.8-1.2-1.9-2.2c0-0.3-0.2-0.4-0.5-0.4c-1.3,0-2.5-1-2.7-2.3
        c-0.1-0.3-0.2-0.4-0.5-0.5c-1.3-0.1-2.4-1-2.6-2.3c0-0.3-0.2-0.4-0.4-0.4c-1.5-0.1-2.7-1.2-2.7-2.7c0-0.3-0.2-0.5-0.4-0.7
        c-3.1-2.4-4.7-6.3-4.2-10.2C0.4,5.2,2.5,2.3,5.5,1c1.2-0.6,2.5-0.9,3.9-0.9l0.3,0l0.3,0c2.2,0,4.3,0.7,6,2.1
        c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2C18.9,0.8,21.2,0,23.5,0c1.2,0,2.5,0.2,3.6,0.6c3.5,1.3,5.5,3.9,6.2,7.8
        c0.5,2.8-0.3,5.7-2.1,7.9c-0.1,0.2-0.2,0.5-0.3,0.8c-0.1,1.2-1.1,2.2-2.2,2.3c-0.3,0-0.5,0.3-0.5,0.5c-0.1,1.4-1.2,2.5-2.5,2.7
        c-0.1,0.1-0.3,0.2-0.3,0.4c-0.1,1.5-1.3,2.8-2.9,2.8l-0.1,0c-0.2,0-0.3,0.1-0.5,0.3c-0.3,0.5-0.6,1.1-0.9,1.6
        c-0.4,0.5-0.8,1-1.2,1.4l-0.5,0.5C18.6,30.2,17.9,30.6,17.1,30.6z M18.9,24.6c-0.2,0-0.3,0-0.5,0.1c-0.8,0.8-1.5,1.6-2.2,2.5
        c-0.2,0.2-0.2,0.5-0.1,0.7c0.2,0.3,0.5,0.5,1,0.5c0.1,0,0.3-0.1,0.4-0.2c0.7-0.7,1.5-1.6,2.2-2.4c0.1-0.2,0.1-0.4,0.1-0.7
        c-0.1-0.2-0.2-0.4-0.4-0.5C19.2,24.6,19.1,24.6,18.9,24.6z M15.8,21.8c-0.2,0-0.3,0.1-0.4,0.2c-0.8,0.8-1.5,1.6-2.2,2.5
        c-0.1,0.2-0.1,0.4-0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.3-0.1c0.8-0.9,1.6-1.8,2.4-2.7
        c0.1-0.1,0.2-0.3,0.1-0.4c0-0.2-0.1-0.3-0.2-0.4L16.5,22C16.3,21.9,16,21.8,15.8,21.8z M9.6,13.8c0.8,0,1.6,0.3,2.3,0.9
        c0.4,0.4,0.7,0.9,0.8,1.4c0,0.3,0.3,0.5,0.5,0.5c1.3,0.2,2.3,1.1,2.6,2.3c0,0.3,0.3,0.5,0.6,0.5c1.4,0.1,2.5,1.1,2.7,2.4
        c0,0.1,0.1,0.3,0.2,0.3c1,0.1,1.8,0.6,2.5,1.3c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.2,0.2-0.3
        c0.3-0.6,0-0.9-0.4-1.2l-1.5-1.4c-1.5-1.4-3.1-2.8-4.6-4.2c-0.5-0.4-0.6-1.1-0.2-1.7c0.3-0.3,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.3
        l2,1.8l0.8,0.7c1.2,1.1,2.4,2.1,3.6,3.2c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.3c0.3-0.4,0.3-0.9-0.1-1.3L22.3,16
        c-1-0.9-2-1.7-2.9-2.6c-0.5-0.4-0.5-1.1-0.1-1.6c0.2-0.3,0.5-0.4,0.9-0.4c0.2,0,0.5,0.1,0.7,0.2c0.2,0.2,0.4,0.3,0.6,0.5
        c1.1,0.9,2.1,1.9,3.2,2.8c0.9,0.8,1.8,1.6,2.7,2.4c0.3,0.2,0.5,0.4,0.8,0.4c0.4,0,0.7-0.2,0.8-0.7c0.2-0.5,0-0.7-0.4-1.1l-8.2-7.5
        c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.9-1.6,1.8-2.4,2.6c-1,1.1-2.5,1.8-4.1,1.8c-1.4,0-2.7-0.5-3.7-1.4
        c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.6c0.9-1,1.8-2,2.7-3l0.3-0.3c0.8-0.9,1.6-1.8,2.5-2.7
        c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1-0.2-0.2-0.3-0.3C13.2,2.5,11.6,2,10,2c-0.4,0-0.8,0-1.2,0.1C5,2.6,2.1,5.9,2.1,9.8
        c0,2.5,1.1,5,3,6.7c0.2,0.2,0.3,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0.5-0.6,1-1.1,1.5-1.7C7.8,14.2,8.7,13.8,9.6,13.8z M12.7,19
        c-0.2,0-0.4,0.1-0.6,0.3c-0.8,0.8-1.4,1.5-2,2.2c-0.3,0.3-0.3,0.8,0.1,1.1l0.1,0.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2
        l0.1-0.1c0.7-0.7,1.4-1.5,2.1-2.3c0.1-0.2,0.2-0.4,0.2-0.6c-0.2-0.3-0.4-0.5-0.7-0.6C13,19,12.8,19,12.7,19z M9.6,16.1
        c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0.1-0.1,0.1c-0.7,0.7-1.4,1.5-2,2.3c-0.2,0.2-0.2,0.5-0.1,0.8C7,19.7,7.2,19.9,7.4,20
        c0.1,0.1,0.2,0.1,0.2,0.2c0.3-0.1,0.5-0.2,0.6-0.3c0.8-0.9,1.5-1.6,2.1-2.3c0.3-0.3,0.2-0.9-0.1-1.1C10,16.2,9.8,16.1,9.6,16.1z
        M19.8,5.4c0.3,0,0.7,0.2,1.2,0.6l2.5,2.2c2.2,1.9,4.3,3.8,6.5,5.8c0.1,0.1,0.3,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.3
        c1.6-2.9,1.2-6.5-1-9c-1.5-1.7-3.8-2.6-6-2.6c-1.9,0-3.8,0.7-5.2,1.9c-1.6,1.5-3.2,3.2-4.7,4.9c-0.5,0.4-0.9,0.9-1.3,1.4l-0.1,0.1
        c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0,0.5,0c0.9,0,1.7-0.4,2.2-1c0.9-0.9,1.7-1.9,2.6-2.8l0.6-0.7C19.1,5.6,19.4,5.4,19.8,5.4z"
    />
  </svg>
);

export default SvgSupport;
