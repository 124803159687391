import { useState, useEffect } from 'react';
import { mdiBookmark, mdiBookmarkOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { getDoc, doc } from 'firebase/firestore';
import useSWR from 'swr';
import dynamic from 'next/dynamic';
import { useAuth } from '@/providers/AuthProvider';
import { db } from '@/firebase/firebase';
import { IPost } from '@/types/Collections/posts';
import PostCollections from './PostCollections';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const CollectionsIntroPopover = dynamic(
  () => import('./PostCollections/CollectionsIntroPopover'),
  {
    ssr: false,
  },
);

const PostCardCollections = ({
  post,
  isTeaser = true,
}: {
  post: IPost;
  isTeaser?: boolean;
}): JSX.Element => {
  const { uid, isVerified, showVerifyEmailDialog } = useAuth();
  const { open: openSignupBanner } = useSignupBanner();
  const tooltipEnabled = useFeatureFlagEnabled(
    '2023_05_post_collections_tooltip',
  );
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeCollections, setActiveCollections] = useState<string[]>([]);

  // TODO: Get all post interactions for post card and move this up to PostCard
  const getBookmarkStatusFetcher = async (dbPath: string) => {
    const ref = doc(db, dbPath);
    return getDoc(ref).then(doc => {
      if (doc.exists()) {
        return doc.data();
      }
      return null;
    });
  };

  const { data: postInteractions, mutate } = useSWR(
    () =>
      uid && post.id
        ? `userInteractions/${uid}/postInteractions/${post.id}`
        : false,
    key => getBookmarkStatusFetcher(key),
  );

  useEffect(() => {
    if (postInteractions) {
      if (postInteractions.collections && postInteractions.collections.length) {
        setActiveCollections(postInteractions.collections);
        setIsBookmarked(true);
      } else {
        setActiveCollections([]);
        setIsBookmarked(false);
      }
    }
  }, [postInteractions]);

  const refreshActiveCollections = (newActiveCollections: string[]) => {
    mutate({
      ...postInteractions,
      collections: newActiveCollections,
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (uid) {
      if (isVerified) {
        setMenuOpen(true);
      } else {
        showVerifyEmailDialog();
      }
    } else {
      openSignupBanner();
    }
  };

  return (
    <div className="control">
      <button
        type="button"
        onClick={handleClick}
        aria-label="Collections"
        className="postcard-collections button is-text is-large">
        <span className="icon">
          <Icon
            path={isBookmarked ? mdiBookmark : mdiBookmarkOutline}
            size={1.5}
          />
        </span>
      </button>
      {!isTeaser && !isBookmarked && tooltipEnabled && (
        <CollectionsIntroPopover />
      )}
      <PostCollections
        post={post}
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        activeCollections={activeCollections}
        refreshActiveCollections={refreshActiveCollections}
      />
    </div>
  );
};

export default PostCardCollections;
