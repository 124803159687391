import { useState } from 'react';
import { mdiLock, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { IPost } from '../../../types/Collections/posts';
import { ICollection } from '../../../types/Collections/collections';
import { addPost, removePost, addBookmarksCollection } from './api';

const CollectionsList = ({
  uid,
  username,
  userCollections,
  hasBookmarks,
  activeCollections,
  refreshActiveCollections,
  post,
}: {
  uid: string;
  username: string;
  userCollections: ICollection[];
  hasBookmarks: boolean;
  activeCollections: string[];
  refreshActiveCollections: (activeCollections: string[]) => void;
  post: IPost;
}): JSX.Element => {
  const [isWaiting, setIsWaiting] = useState<string | null>(null);

  const handleCollectionToggle = async (
    e: React.MouseEvent<HTMLButtonElement>,
    collectionId: string,
  ) => {
    e.preventDefault();
    setIsWaiting(collectionId);

    if (collectionId === 'bookmarks' && !hasBookmarks) {
      await addBookmarksCollection(uid, username);
      await addPost(uid, post.id, collectionId).then(() => {
        setIsWaiting(null);
        refreshActiveCollections([...activeCollections, collectionId]);
      });
    } else if (activeCollections.includes(collectionId)) {
      await removePost(uid, post.id, collectionId).then(() => {
        setIsWaiting(null);
        refreshActiveCollections(
          activeCollections.filter(id => id !== collectionId),
        );
      });
    } else {
      await addPost(uid, post.id, collectionId).then(() => {
        setIsWaiting(null);
        refreshActiveCollections([...activeCollections, collectionId]);
      });
    }
  };

  return (
    <ul className="collections-list">
      {userCollections.map(
        userCollection =>
          !userCollection.isDeleted && (
            <li key={userCollection.id}>
              <button
                type="button"
                onClick={event =>
                  handleCollectionToggle(event, userCollection.id)
                }
                className={`collection-toggle-button button is-fullwidth${
                  activeCollections.includes(userCollection.id)
                    ? ` is-primary is-light`
                    : ` is-white`
                }${isWaiting === userCollection.id ? ` is-loading` : ``}`}>
                <span className="collection-toggle-button-left-icon icon is-justify-content-left has-text-primary">
                  {activeCollections.includes(userCollection.id) && (
                    <Icon className="mr-2" path={mdiCheckCircle} size={0.75} />
                  )}
                </span>
                <span className="collection-toggle-button-text">
                  {userCollection.title}
                </span>
                <span className="collection-toggle-button-right-icon icon is-flex-grow-1 is-justify-content-right">
                  {userCollection?.isPrivate && (
                    <Icon className="ml-2" path={mdiLock} size={0.75} />
                  )}
                </span>
              </button>
            </li>
          ),
      )}
      {!hasBookmarks && (
        <li key="bookmarks">
          <button
            type="button"
            onClick={event => handleCollectionToggle(event, 'bookmarks')}
            className={`collection-toggle-button button is-fullwidth${
              activeCollections.includes('bookmarks')
                ? ` is-primary is-light`
                : ` is-white`
            }${isWaiting === 'bookmarks' ? ` is-loading` : ``}`}>
            <span className="collection-toggle-button-left-icon icon is-flex-grow-1 is-justify-content-left has-text-primary">
              {activeCollections.includes('bookmarks') && (
                <Icon className="mr-2" path={mdiCheckCircle} size={0.75} />
              )}
            </span>
            <span className="collection-toggle-button-text">My Bookmarks</span>
            <span className="collection-toggle-button-right-icon icon is-flex-grow-1 is-justify-content-right">
              <Icon className="ml-2" path={mdiLock} size={0.75} />
            </span>
          </button>
        </li>
      )}
    </ul>
  );
};

export default CollectionsList;
