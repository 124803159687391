import { ReactElement } from 'react';

const defaultProps = {
  disabled: false,
};

type SwitchProps = {
  name: string;
  id: string;
  label: string;
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
} & typeof defaultProps;

const Switch = ({
  name,
  id,
  label,
  checked,
  handleChange,
  disabled = false,
}: SwitchProps): ReactElement => (
  <div className="field settings-switch">
    <div className="control">
      <input
        id={id}
        type="checkbox"
        aria-label="checkbox"
        name={name}
        className="switch is-rounded"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  </div>
);

Switch.defaultProps = defaultProps;

export default Switch;
