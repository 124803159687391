import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import SupportIcon from '@/components/svg/SupportIcon';
import { postUrl } from '@/lib/utils';
import { IPost } from '@/types/Collections/posts';
import useReaction from '@/hooks/useReaction';
import { useSignupBanner } from '@/providers/SignupBannerProvider';

const PostCardReaction = ({
  post,
}: {
  post: IPost;
  isTeaser: boolean;
}): JSX.Element => {
  const { uid, isVerified, showVerifyEmailDialog } = useAuth();
  const { open: openSignupBanner } = useSignupBanner();
  const { reaction, addReaction, deleteReaction } = useReaction(
    'post',
    post.id,
    uid,
  );

  const router = useRouter();

  const route = useMemo(() => {
    if (router.route) {
      const routeName = router.route.split('/');
      if (routeName.length > 1) {
        return routeName[1];
      }
    }

    return null;
  }, [router.route]);

  let postReactionsCount = 0;

  if (post.reactions) {
    const postReactions = Object.values(post.reactions);
    postReactionsCount = postReactions.reduce((a, b) => a + b, 0);
  }

  const [reactionsCount, setReactionsCount] = useState(postReactionsCount);

  const handleSupportClick = () => {
    if (uid && isVerified) {
      if (reaction) {
        deleteReaction();
        gtag.event({
          action: 'post.reaction.remove',
          params: {
            postId: post.id,
            postTitle: post.title,
            reactionType: 'support',
            uid,
          },
        });
        setReactionsCount(reactionsCount - 1);
      } else {
        addReaction();
        gtag.event({
          action: 'post.reaction.add',
          params: {
            postId: post.id,
            postTitle: post.title,
            reactionType: 'support',
            uid,
          },
        });
        setReactionsCount(reactionsCount + 1);
      }
    } else if (uid) {
      showVerifyEmailDialog();
    } else {
      openSignupBanner();
    }
    return null;
  };

  return (
    <div className="control">
      {route !== 'embedPost' ? (
        <button
          type="button"
          onClick={handleSupportClick}
          aria-label="Support"
          className="postcard-support button button-has-count is-text is-large">
          <span className="icon">
            <SupportIcon
              fill={reaction ? '#EB5E62' : '#363636'}
              width={36}
              height={36}
            />
          </span>
          {reactionsCount > 0 ? (
            <span className="button-count tag is-warning">
              {reactionsCount}
            </span>
          ) : (
            ''
          )}
        </button>
      ) : (
        <Link href="/[...slug]" as={postUrl(post)} legacyBehavior>
          <a
            aria-label="Support"
            className="postcard-support button button-has-count is-text is-large"
            target="_top">
            <span className="icon">
              <SupportIcon
                fill={reaction ? '#EB5E62' : '#363636'}
                width={36}
                height={36}
              />
            </span>
            {reactionsCount > 0 ? (
              <span className="button-count tag is-warning">
                {reactionsCount}
              </span>
            ) : (
              ''
            )}
          </a>
        </Link>
      )}
    </div>
  );
};

export default PostCardReaction;
