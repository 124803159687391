import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { db } from '@/firebase/firebase';
import eventAdd from '@/lib/eventAdd';
import { useAuth } from '@/providers/AuthProvider';
import PollOption from './PollOptions';
import { IPost, IPollOption } from '@/types/Collections/posts';
import { useSignupBanner } from '@/providers/SignupBannerProvider';

const PollCard = ({
  post,
  expanded,
}: {
  post: IPost;
  expanded: boolean;
}): JSX.Element | null => {
  const { authLoaded, uid, isVerified, auth, showVerifyEmailDialog } =
    useAuth();
  const { open: openSignupBanner } = useSignupBanner();

  const voteFetcher = async (key: string) => {
    const docRef = doc(db, key);
    return getDoc(docRef).then(data => {
      if (data.exists()) {
        return true;
      }
      return false;
    });
  };

  const {
    data: userHasVoted,
    error: errorUserHasVoted,
    mutate: mutateUserHasVoted,
  } = useSWR(
    uid && post.id ? `posts/${post.id}/pollVoters/${uid}` : null,
    voteFetcher,
  );

  const totalVotes = useMemo(() => {
    let totalCount = 0;
    if (post.poll) {
      post.poll?.options?.forEach(opt => {
        totalCount += opt.vote;
      });
    }
    return totalCount;
  }, [post.poll]);

  useEffect(() => {
    if (authLoaded && uid && auth?.email && post.poll && expanded) {
      eventAdd({
        uid,
        eventType: 'POLL:VIEW',
        eventSource: 'POLL',
        eventSourceId: post.id,
        meta: {},
        email: auth.email,
        platform: 'web',
      });
    }
  }, [authLoaded, uid, auth?.email, post.id, expanded, post.poll]);

  const getPollPercentage = useCallback(
    (option: IPollOption) =>
      option.vote === 0 && totalVotes === 0
        ? 0
        : Math.round((option.vote / totalVotes) * 100),
    [totalVotes],
  );

  const handleVote = async (option: IPollOption, index: number) => {
    if (uid && isVerified) {
      const tempOptions = post?.poll?.options ? [...post.poll.options] : [];
      const vote = option.vote + 1;
      tempOptions[index] = {
        ...option,
        vote,
      };

      const poll = {
        ...post.poll,
        options: tempOptions,
      };

      if (uid && auth?.email) {
        eventAdd({
          uid,
          eventType: 'POLL:ANSWER',
          eventSource: 'POLL',
          eventSourceId: post.id,
          meta: {},
          email: auth.email,
          platform: 'web',
        });
      }

      post.poll = poll;

      await setDoc(doc(db, 'posts', post.id, 'pollVoters', uid), {
        timestamp: serverTimestamp(),
        option,
      }).then(() => {
        mutateUserHasVoted(true);
      });
    } else if (uid) {
      showVerifyEmailDialog();
    } else {
      openSignupBanner();
    }
  };

  if (errorUserHasVoted) {
    return <div className="has-text-danger">{errorUserHasVoted.message}</div>;
  }

  if (uid && userHasVoted === undefined) {
    return <div className="loading-block" />;
  }

  return (
    <div>
      <div className="is-flex is-flex-direction-column mb-5">
        <p className="has-text-weight-bold">{post?.poll?.question}</p>
      </div>
      <div>
        {post?.poll?.options.map((option, index) => (
          <div key={option.id}>
            {userHasVoted ? (
              <PollOption
                option={option}
                getPollPercentage={getPollPercentage}
              />
            ) : (
              <button
                type="button"
                className="button is-fullwidth is-rounded is-grey-dark mt-3"
                onClick={() => {
                  handleVote(option, index);
                }}>
                {option.value}
              </button>
            )}
          </div>
        ))}
      </div>
      <p className="is-align-self-flex-end is-size-7 is-light">{`Total answers: ${totalVotes}`}</p>
    </div>
  );
};

export default PollCard;
