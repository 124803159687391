import { useState } from 'react';
import Image from 'next/image';
import * as Popover from '@radix-ui/react-popover';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

const PostCardAuthorBadge = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handlePopoverToggle = async (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <span className="mr-1">
      <Popover.Root open={isOpen} onOpenChange={handlePopoverToggle}>
        <Popover.Trigger asChild>
          <button
            type="button"
            className="button is-ghost"
            onClick={event => {
              event.preventDefault;
              setIsOpen(true);
            }}>
            <Image
              src="/assets/images/otc_supporter_icon.png"
              alt=""
              width={24}
              height={24}
            />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="top"
            onInteractOutside={event => event?.preventDefault()}
            onEscapeKeyDown={event => event?.preventDefault()}
            onFocusOutside={event => event?.preventDefault()}
            className="popover-content">
            <p className="is-font-brand-bold">One Tap Connection supporter</p>
            <div className="buttons">
              <a
                href="https://shift.ms/get-involved/donate"
                target="_blank"
                onClick={() => handlePopoverToggle(false)}
                className="button is-dark is-fullwidth">
                Find out more
              </a>
            </div>
            <Popover.Close
              className="popover-close button is-light is-small is-rounded is-info"
              aria-label="Close">
              <span className="icon is-small">
                <Icon path={mdiClose} size={0.75} />
              </span>
            </Popover.Close>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </span>
  );
};

export default PostCardAuthorBadge;
