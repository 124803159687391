import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { functions } from '../../../firebase/firebase';
import useInput from '../../../hooks/inputHook';
import * as gtag from '../../../lib/gtag';
import { useAuth } from '../../../providers/AuthProvider';

const PostOptionsReport = ({ post, onClose, setDialogType }) => {
  const { uid } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState(false);
  const {
    value: reason,
    bind: bindReason,
    reset: resetReason,
  } = useInput('spam');
  const {
    value: content,
    bind: bindContent,
    reset: resetContent,
  } = useInput('');

  const reportPost = async (reportData) => {
    const report = httpsCallable(functions, 'moderationCallable-report');

    const reportObj = { ...reportData };
    reportObj.objectType = 'post';
    reportObj.objectId = post.id;
    reportObj.objectParentId = null;
    reportObj.objectTitle = post.title;
    reportObj.objectAuthor = post.author;

    return report(reportObj)
      .then(() => {
        gtag.event({
          action: 'post.report',
          params: {
            postId: post.id,
            reporterUid: uid,
            reason: reportObj.reason,
          },
        });
        enqueueSnackbar(
          'Thank you. Your report has been sent to our moderators for review.',
          { variant: 'success' },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        return false;
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const report = {
      reason,
      content,
    };
    if (reason === 'other' && report.content === '') {
      setError(true);
    } else {
      setError(false);
      reportPost(report);
      resetReason();
      resetContent();
      handleClose();
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label htmlFor="post-options-report-reason" className="label">
              Please let us know your reasons for reporting this post
            </label>
            <div className="control">
              <div className="select">
                <select id="post-options-report-reason" {...bindReason}>
                  <option value="spam">This post is spam</option>
                  <option value="offensive">This post is offensive</option>
                  <option value="abusive">This post is abusive</option>
                  <option value="personal">
                    This post contains personal contact information
                  </option>
                  <option value="worried">
                    I am worried about this person/member
                  </option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="post-options-report-content" className="label">
              Please provide any further relevant information
            </label>
            <div className="control">
              <textarea
                id="post-options-report-content"
                className="textarea"
                placeholder=""
                rows="3"
                {...bindContent}
              />
            </div>
            {error ? (
              <p className="help is-danger">Please enter more information.</p>
            ) : (
              ''
            )}
          </div>
          <div className="buttons modal-footer-buttons">
            <button
              type="button"
              aria-label="Close"
              onClick={() => setDialogType('default')}
              className="button is-light is-medium"
            >
              Back
            </button>
            <button
              type="submit"
              aria-label="OK"
              className="button is-primary is-medium"
            >
              Submit report
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

PostOptionsReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  setDialogType: PropTypes.func.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({
      username: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PostOptionsReport;
