import useSWR from 'swr';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { IUser } from '@/types/Collections/users';

type UseUser = {
  user: IUser | null | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
};

const useUser = (uid: string | null): UseUser => {
  const fetcher = async (key: string) => {
    const [, , uid] = key.split('/');

    const ref = doc(db, 'users', uid);

    const user = await getDoc(ref);
    if (user.exists()) {
      return user.data() as IUser;
    }
    throw new Error('User not found.');
  };

  const { data, error, isLoading, isValidating } = useSWR(
    uid ? `/users/${uid}` : null,
    fetcher,
  );

  return {
    user: data,
    isLoading,
    isValidating,
    error,
  };
};

export default useUser;
