export enum MSTypesEnum {
  'Relapsing remitting' = 'Relapsing remitting',
  'Secondary progressive' = 'Secondary progressive',
  'Primary progressive' = 'Primary progressive',
  'Benign' = 'Benign',
  "Don't know / other" = "Don't know / other",
}

export const msTypesShorthand = {
  'relapsing remitting': 'RRMS',
  'secondary progressive': 'SPMS',
  'primary progressive': 'PPMS',
  benign: 'Benign MS',
};

const MSTypes = [
  MSTypesEnum['Relapsing remitting'],
  MSTypesEnum['Secondary progressive'],
  MSTypesEnum['Primary progressive'],
  MSTypesEnum.Benign,
  MSTypesEnum["Don't know / other"],
];

export default MSTypes;
