import { IMSImportantActivities } from '../../lib/msImportantActivities';
import { MSConcernType } from '../../lib/msImportantFactorsInDmtDecision';
import { msWhoInfluencedDmtDecision } from '../../lib/msWhoInfluencedDmtDecisionList';
import { MSReasonsForNoDmtTypes } from '../../lib/msReasonsForNoDMT';
import { MSTypesEnum } from '../../lib/mstypes';
import { Timestamp } from '../Firebase/firebase';

export enum MSConnection {
  'I have MS' = 'I have MS',
  'I might have MS' = 'I might have MS',
  'I know someone with MS' = 'I know someone with MS',
  'I work within the MS community' = 'I work within the MS community',
  'Other' = 'Other',
}

export enum Treatment {
  'Not on treatment' = 'Not on treatment',
  'Avonex' = 'Avonex',
  'Betaferon' = 'Betaferon',
  'Copaxone' = 'Copaxone',
  'Rebif' = 'Rebif',
  'Gilenya' = 'Gilenya',
  'Tysabri' = 'Tysabri',
  'Lemtrada' = 'Lemtrada',
  'Tecfidera' = 'Tecfidera',
  'Aubagio' = 'Aubagio',
  'Extavia' = 'Extavia',
  'Plegridy' = 'Plegridy',
  'Mitoxantrone' = 'Mitoxantrone',
  'Ocrevus' = 'Ocrevus',
  'Cladribine/Mavenclad' = 'Cladribine/Mavenclad',
  'HSCT' = 'HSCT',
  'Zinbryta' = 'Zinbryta',
  'Other' = 'Other',
}

export enum Region {
  'England' = 'England',
  'Scotland' = 'Scotland',
  'Wales' = 'Wales',
  'Northern Ireland' = 'Northern Ireland',
  'Other' = 'Other',
  'Alabama' = 'Alabama',
  'Alberta' = 'Alberta',
  'Australian Capital Territory' = 'Australian Capital Territory',
}

export interface ILastGeopoint {
  _latitude: number;
  _longitude: number;
}

export interface IGeoInfo {
  countryCode: string;
  country: string;
  region: string;
  city: string;
  lat: string;
  lon: string;
}

export enum YesNoRadioType {
  'yes' = 'yes',
  'no' = 'no',
}

export enum ProfilePage {
  'posts' = 'posts',
  'about' = 'about',
  'collections' = 'collections',
  'followers' = 'followers',
  'following' = 'following',
}

export interface IUser {
  bio?: string;
  commentsCount: number;
  country: string;
  countryName: string;
  created: Timestamp;
  modified: Timestamp;
  dob: Timestamp | Date | string;
  dobString: string;
  followerCount: number;
  followingCount: number;
  gender: string;
  genderOption: string;
  isActivated: boolean;
  isDeleted: boolean;
  lastActive: Timestamp;
  msConnection: MSConnection | '';
  msType: MSTypesEnum | '';
  postsCount: number;
  profileImgUrl: string;
  profileImgUrlThumb?: string;
  profileImgUrlLastUpload: Timestamp;
  profileImgName?: string;
  region: Region | '';
  streamAccessToken: string;
  treatment: Treatment | '';
  username: string;
  usernameLowercase: string;
  monthDiagnosed: number | '';
  yearDiagnosed: number | '';
  deletedDate?: Timestamp;
  deletedBy?: string;
  deletedReason?: string;
  geoInfo?: IGeoInfo;
  city?: string;
  lastLogin?: Timestamp;
  lastGeopoint?: ILastGeopoint;
  lastGeopointUpdated?: any;
  hubspotUserId?: string;
  msImportantActivitiesList?: IMSImportantActivities[];
  isTakingDMT: YesNoRadioType | '';
  monthDmtStarted: number | '';
  yearDmtStarted: number | '';
  isOnDesiredDMT: YesNoRadioType | '';
  dmtInfluentialPerson: msWhoInfluencedDmtDecision | '';
  dmtRecommendationScore: number | '';
  importantFactorInDmtDecision: MSConcernType | '';
  reasonForNotUsingDMT: MSReasonsForNoDmtTypes | '';
  isUserActivitiesPopulated?: boolean;
  treatmentType?: string;
  county?: string;
  hcpReferral?: 'yes' | 'no' | '';
  isDonor?: boolean;
  donationDate?: Timestamp;
}

export interface IProfile extends IUser {
  uid: string;
}

export interface IAuthor {
  uid: string;
  username: string;
  profileImgUrl?: string | null;
  created?: Timestamp;
  lessThan3Posts?: boolean;
}

export interface ILocation {
  lat: any;
  lng: any;
}

export interface IAlgoliaDoc {
  geohashFuzzy?: string;
  bio: string;
  created: any;
  modified: any;
  dob: any;
  country: string | null;
  countryName: string | null;
  city: string | null;
  _geoloc: ILocation | null;
  location: { lvl0: string; lvl1?: string } | null;
  lastGeopointUpdated: number | null;
  geohash: any;
  lastLogin: any;
  lastActive: any;
  msConnection: MSConnection;
  msType: MSTypesEnum;
  yearDiagnosed: string;
  treatment: Treatment;
  profileImgUrl: string;
  profileImgUrlThumb?: string;
  username: string;
  uid: string;
  ranking: number;
  region: string | null;
}

export interface IUserActivity {
  post: any;
  isDeleted: boolean;
  isPublished: boolean;
  created: Date;
  commentsCount: number;
}
