import { mdiCommentOutline } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { postUrl } from '@/lib/utils';
import { IPost } from '@/types/Collections/posts';

const PostCardComments = ({
  post,
}: {
  post: IPost;
  isTeaser: boolean;
}): JSX.Element => {
  const router = useRouter();

  const route = useMemo(() => {
    if (router.route) {
      const routeName = router.route.split('/');
      if (routeName.length > 1) {
        return routeName[1];
      }
    }

    return null;
  }, [router.route]);

  return (
    <div className="control">
      <Link legacyBehavior href="/[...slug]?postReply=true" as={postUrl(post)}>
        <a
          aria-label="Comments"
          className="button is-text is-large button-has-count"
          title="View comments"
          target={route === 'embedPost' ? '_top' : undefined}>
          <span className="icon">
            <Icon path={mdiCommentOutline} size={1.5} />
          </span>
          {post.commentsCount > 0 ? (
            <span className="button-count tag is-warning">
              {post.commentsCount}
            </span>
          ) : (
            ''
          )}
        </a>
      </Link>
    </div>
  );
};

export default PostCardComments;
