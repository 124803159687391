import AuthorMiniBio from './AuthorMiniBio';
import ModalLink from '../General/ModalLink';
import PostCardAuthorBadge from './PostCardAuthorBadge';
import { IAuthor } from '@/types/Collections/users';
import useUser from '@/hooks/useUser';

const PostCardAuthor = ({
  author,
  route,
}: {
  author: IAuthor;
  route: string | null;
}): JSX.Element => {
  const { user, error } = useUser(author.uid ? author.uid : null);

  return (
    <p className="postcard-author-content is-font-brand-bold">
      <span className="postcard-author-username">
        <ModalLink
          as={`/@${author.username}`}
          params={{
            username: author.username,
          }}>
          <a
            target={route === 'embedPost' ? '_top' : undefined}
            className="mr-1">
            @{author.username}
          </a>
        </ModalLink>
        {user && user.isDonor && <PostCardAuthorBadge />}
      </span>
      <AuthorMiniBio user={user} error={error} />
    </p>
  );
};

export default PostCardAuthor;
