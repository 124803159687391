import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ShiftmsUrlPreview = ({ url }) => {
  const [isLoading, setLoading] = useState(true);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      const data = await fetch(`/api/og?url=${url}`);
      const previewData = await data.json();
      if (previewData.success) {
        setPreview(previewData);
      }
      setLoading(false);
    };
    fetchUrl();
  }, [url]);

  if (isLoading) {
    return <div className="loading-block has-background-light" />;
  }

  if (preview && preview.ogTitle) {
    let image = false;
    if (preview.ogImage) {
      if (Array.isArray(preview.ogImage)) {
        image = preview.ogImage[0].url;
      } else {
        image = preview.ogImage.url;
      }
      if (image && image.substring(0, 1) === '//') {
        image = `http:${image}`;
      }
    }

    return (
      <div className="shiftms-url-preview">
        <a href={url} target="_blank" rel="noreferrer">
          {image ? (
            <div
              className="shiftms-url-image image is-16by9"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          ) : (
            ''
          )}
          <div className="shiftms-url-content">
            <div className="shiftms-url-content-text">
              <h4>{preview.ogTitle}</h4>
              {preview.ogDescription ? <p>{preview.ogDescription}</p> : ''}
            </div>
            <div className="shiftms-url-website">
              {preview.favicon ? (
                <Image src={preview.favicon} alt="" width={16} height={16} />
              ) : (
                ''
              )}
            </div>
          </div>
        </a>
      </div>
    );
  }
  return null;
};

ShiftmsUrlPreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ShiftmsUrlPreview;
