import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { mdiClose, mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import useSWR, { SWRResponse } from 'swr';
import { useAuth } from '../../../providers/AuthProvider';
import { IPost } from '../../../types/Collections/posts';
import { ICollection } from '../../../types/Collections/collections';
import { getUserCollections } from './api';
import CollectionsList from './CollectionsList';
import NewCollection from './NewCollection';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

const PostCollections = ({
  post,
  activeCollections,
  refreshActiveCollections,
  open,
  onClose,
}: {
  post: IPost;
  activeCollections: string[];
  refreshActiveCollections: (newActiveCollections: string[]) => void;
  open: boolean;
  onClose: () => void;
}): JSX.Element => {
  const { uid, user } = useAuth();
  const [hasBookmarks, setHasBookmarks] = useState(false);
  const [userCollections, setUserCollections] = useState<ICollection[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(false);

  const {
    data: userCollectionsData,
    mutate,
  }: SWRResponse<{ collections: ICollection[]; hasBookmarks: boolean } | null> =
    useSWR(
      () => (uid ? `userCollections/${uid}` : false),
      () => (uid ? getUserCollections(uid) : null),
    );

  useEffect(() => {
    if (userCollectionsData) {
      setUserCollections(userCollectionsData.collections);
      setHasBookmarks(userCollectionsData.hasBookmarks);
      setIsLoading(false);
    }
  }, [userCollectionsData]);

  const handleClose = () => {
    onClose();
  };

  const refreshUserCollections = async (newCollection: ICollection) => {
    setIsLoading(true);
    let hasBookmarks = false;
    if (userCollectionsData?.hasBookmarks || newCollection.id === 'bookmarks') {
      hasBookmarks = true;
    }
    let collections = [] as ICollection[];
    if (userCollectionsData?.collections) {
      ({ collections } = userCollectionsData);
    }
    mutate({
      collections: [...collections, newCollection],
      hasBookmarks,
    });
  };

  const modalContent = () => {
    if (uid && user?.username) {
      return (
        <div className="card collections-dialog">
          <div className="card-content">
            <div className="modal-header">
              <h2 className="is-size-4" id="post-collections-title">
                Manage Collections
              </h2>
              <div className="buttons modal-close-button">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={() => handleClose()}
                  className="button is-light">
                  <span className="icon">
                    <Icon path={mdiClose} size={1.25} />
                  </span>
                </button>
              </div>
            </div>
            {isLoading ? (
              <div className="loading-block" />
            ) : (
              <div>
                {isNew ? (
                  <NewCollection
                    uid={uid}
                    username={user.username}
                    setIsNew={setIsNew}
                    post={post}
                    activeCollections={activeCollections}
                    refreshActiveCollections={refreshActiveCollections}
                    refreshUserCollections={refreshUserCollections}
                  />
                ) : (
                  <div>
                    <CollectionsList
                      uid={uid}
                      username={user.username}
                      userCollections={userCollections}
                      hasBookmarks={hasBookmarks}
                      post={post}
                      activeCollections={activeCollections}
                      refreshActiveCollections={refreshActiveCollections}
                    />
                    <button
                      type="button"
                      onClick={() => setIsNew(true)}
                      className="collection-toggle-button button is-fullwidth">
                      <span className="collection-toggle-button-left-icon icon is-flex-grow-1 is-justify-content-left has-text-primary">
                        <Icon path={mdiPlusCircle} size={0.75} />
                      </span>
                      <span className="collection-toggle-button-text">
                        Start a new collection
                      </span>
                      <span className="collection-toggle-button-right-icon is-flex-grow-1" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="post-collections-title">
      {modalContent()}
    </Dialog>
  );
};

export default PostCollections;
