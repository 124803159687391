import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import { doc, updateDoc } from 'firebase/firestore';
import { postUrl } from '../../../lib/utils';
import { useAuth } from '../../../providers/AuthProvider';
import eventAdd from '../../../lib/eventAdd';
import PostOptionsDelete from './PostOptionsDelete';
import PostOptionsModerate from './PostOptionsModerate';
import PostReportConfirm from './PostReportConfirm';
import { db } from '../../../firebase/firebase';
import { IPost } from '../../../types/Collections/posts';
import { ICollection } from '../../../types/Collections/collections';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

interface IProps {
  post: IPost;
  open: boolean;
  collection: ICollection | null;
  onClose: () => void;
  expanded: boolean;
}

const PostOptionsMenu: React.FC<IProps> = ({
  post: userPosts,
  onClose,
  open,
  collection = null,
  expanded,
}: IProps) => {
  const [post, setPost] = useState(userPosts);
  const [isWaitingIndex, setIsWaitingIndex] = useState(false);
  const { isLoggedIn, isAdmin, uid, auth } = useAuth();

  const [modalType, setModalType] = useState('default');
  const router = useRouter();

  const update = async (payload: { isIndexed: boolean }) => {
    try {
      if (post.id && isAdmin) {
        setIsWaitingIndex(true);
        await updateDoc(doc(db, 'posts', post.id), payload);
        await fetch('/api/revalidate?secret=cmrne47ar7tc63xkucm23g5u9eh8jaaq', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: postUrl(post) }),
        });
        setIsWaitingIndex(false);
        setPost(pre => ({ ...pre, isIndexed: !post.isIndexed }));
      }
    } catch (err) {
      // console.log(err, 'Error!');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleExited = () => {
    setModalType('default');
  };

  const handleReplyToPost = () => {
    if (uid && auth?.email) {
      eventAdd({
        uid,
        eventType: 'POST:REPLY_BTN_MODAL_CLICK',
        eventSource: 'POST',
        eventSourceId: post.id,
        meta: {},
        email: auth.email,
        platform: 'web',
      });
    }
    const [route] = router.asPath.split('?');
    const slug = route.split('/');

    if (slug.length > 1) {
      router.push(`/${slug[1]}/${slug[2]}?postReply=true`, undefined, {
        scroll: false,
      });
      onClose();
    }
  };

  const handleRefreshStreamId = async () => {
    if (isLoggedIn && isAdmin) {
      // refreshStreamId(post);
    }
  };

  const content = () => {
    switch (modalType) {
      case 'lock':
      case 'unlock':
      case 'publish':
      case 'unpublish':
        return (
          <PostOptionsModerate
            onClose={handleClose}
            post={post}
            action={modalType}
          />
        );

      case 'delete':
      case 'undelete':
        return (
          <PostOptionsDelete
            onClose={handleClose}
            post={post}
            action={modalType}
            collection={collection}
          />
        );

      case 'report':
        return (
          <PostReportConfirm
            setModelType={setModalType}
            onClose={handleClose}
            post={post}
          />
        );

      default:
        return (
          <div className="card small-dialog chatroom-modal">
            <div className="card-content">
              <div className="modal-header">
                <h2 id="post-options-title" className="is-size-4">
                  Post options
                </h2>
                <div className="buttons modal-close-button">
                  <button
                    type="button"
                    aria-label="Close"
                    onClick={() => handleClose()}
                    className="button is-light">
                    <span className="icon">
                      <Icon path={mdiClose} size={1.25} />
                    </span>
                  </button>
                </div>
              </div>
              <div className="buttons">
                {post.author.uid && post.author.uid === uid ? (
                  <>
                    <Link
                      legacyBehavior
                      href="/[...slug]"
                      as={`${postUrl(post)}/edit`}
                      passHref>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="button is-fullwidth is-dark is-outlined is-medium">
                        Edit post
                      </button>
                    </Link>
                    {post.isDeleted ? (
                      <button
                        type="button"
                        onClick={() => setModalType('undelete')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Restore post
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setModalType('delete')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Delete post
                      </button>
                    )}
                  </>
                ) : (
                  ''
                )}
                {expanded && (
                  <button
                    type="button"
                    onClick={handleReplyToPost}
                    className="button reply-comment-button is-fullwidth is-outlined is-medium">
                    Reply to this post
                  </button>
                )}
                {isAdmin ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        update({
                          isIndexed: !post.isIndexed,
                        });
                      }}
                      className={`button is-fullwidth is-outlined is-medium${
                        isWaitingIndex ? ` is-loading` : ``
                      }`}>
                      {post.isIndexed
                        ? 'Ask Google not to index'
                        : 'Ask Google to index'}
                    </button>
                    {post.isLocked ? (
                      <button
                        type="button"
                        onClick={() => setModalType('unlock')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Unlock post
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setModalType('lock')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Lock post
                      </button>
                    )}
                    {post.isPublished ? (
                      <button
                        type="button"
                        onClick={() => setModalType('unpublish')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Unpublish post
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setModalType('publish')}
                        className="button is-danger is-fullwidth is-outlined is-medium">
                        Publish post
                      </button>
                    )}
                    {!post.streamActivityId ? (
                      <button
                        type="button"
                        onClick={handleRefreshStreamId}
                        className="button is-fullwidth is-outlined is-medium">
                        Refresh Stream ID
                      </button>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
                <button
                  type="button"
                  onClick={() => setModalType('report')}
                  className="button is-danger is-fullwidth is-outlined is-medium">
                  Report this post
                </button>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      aria-labelledby="post-options-title">
      {content()}
    </Dialog>
  );
};

export default PostOptionsMenu;
