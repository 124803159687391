import Link from 'next/link';
import { useRouter } from 'next/router';

type ModalLinkParams = {
  postId?: string;
  collectionId?: string;
  username?: string;
};

const ModalLink = ({
  children,
  as,
  params,
}: {
  children: React.ReactNode;
  as: string;
  params: ModalLinkParams;
}): JSX.Element => {
  const router = useRouter();
  let href = router.pathname;

  if (router.query.query) {
    href = `/search?query=[${router.query.query}]`;
  } else {
    href = `${href}?1=1`;
  }

  if (router.query.filter) {
    href = `/home/[[...filter]]?filter=[${router.query.filter[0]}]`;
  }

  if (params.collectionId && params.postId) {
    href = `${href}&collectionId=${params.collectionId}&postId=${params.postId}&username=${params.username}`;
  } else if (params.postId && params.username) {
    href = `${href}&postId=${params.postId}&username=${params.username}`;
  } else if (params.username) {
    href = `${href}&username=${params.username}`;
  }

  return (
    <Link
      href={`${href}&prev=${router.asPath}`}
      as={as}
      scroll={false}
      shallow={true}
      legacyBehavior>
      {children}
    </Link>
  );
};

export default ModalLink;
