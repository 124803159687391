import { mdiUndoVariant } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

const BackButton = ({ handleBack }) => (
  <button
    type="button"
    aria-label="Close"
    onClick={handleBack}
    className="button"
  >
    <span className="icon">
      <Icon path={mdiUndoVariant} size={1.25} />
    </span>
    <span>Back</span>
  </button>
);

BackButton.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default BackButton;
