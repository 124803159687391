import useSWR from 'swr';
import { getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';

type UseReaction = {
  reaction: string | false | undefined;
  addReaction: () => Promise<void>;
  deleteReaction: () => Promise<void>;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
};

const useReaction = (
  type: 'post' | 'comment',
  objectId: string | null,
  uid: string | null,
): UseReaction => {
  const path = `/${type}Reactions/${objectId}/userReactions/${uid}`;

  const fetcher = async (key: string) => {
    const ref = doc(db, key);
    const reaction = await getDoc(ref);
    if (reaction.exists()) {
      return reaction.data().type;
    }
    return false;
  };

  const { data, mutate, error, isLoading, isValidating } = useSWR(
    objectId && uid ? path : null,
    fetcher,
  );

  const addReaction = async () => {
    const updatedAt = new Date();
    const docRef = doc(db, path);
    await setDoc(docRef, {
      created: updatedAt,
      modified: updatedAt,
      type: 'support',
    }).then(() => {
      mutate();
    });
  };

  const deleteReaction = async () => {
    const docRef = doc(db, path);
    await deleteDoc(docRef).then(() => {
      mutate();
    });
  };

  return {
    reaction: data,
    addReaction,
    deleteReaction,
    isLoading,
    isValidating,
    error,
  };
};

export default useReaction;
