import { mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import Link from 'next/link';
import { useAuth } from '@/providers/AuthProvider';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import { postUrl } from '@/lib/utils';
import PostOptionsMenu from './PostOptionsMenu';
import { IPost } from '@/types/Collections/posts';
import { ICollection } from '@/types/Collections/collections';

const PostOptions = ({
  post,
  collection = null,
  expanded,
}: {
  post: IPost;
  collection?: ICollection | null;
  expanded: boolean;
}): JSX.Element => {
  const { isLoggedIn, isVerified, showVerifyEmailDialog } = useAuth();
  const { open: openSignupBanner } = useSignupBanner();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const router = useRouter();

  const route = useMemo(() => {
    if (router.route) {
      const routeName = router.route.split('/');
      if (routeName.length > 1) {
        return routeName[1];
      }
    }

    return null;
  }, [router.route]);

  const handleMenuOpen = () => {
    if (isLoggedIn) {
      if (isVerified) {
        setMenuOpen(true);
      } else {
        showVerifyEmailDialog();
      }
    } else {
      openSignupBanner();
    }
  };

  return (
    <div className="control">
      {route !== 'embedPost' ? (
        <button
          type="button"
          aria-label="Options"
          onClick={handleMenuOpen}
          className="button is-text is-large">
          <span className="icon">
            <Icon path={mdiDotsHorizontal} size={1.25} />
          </span>
        </button>
      ) : (
        <Link href="/[...slug]" as={postUrl(post)} legacyBehavior>
          <a
            aria-label="Options"
            className="button is-text is-large"
            target="_top">
            <span className="icon">
              <Icon path={mdiDotsHorizontal} size={1.5} />
            </span>
          </a>
        </Link>
      )}

      <PostOptionsMenu
        post={post}
        expanded={expanded}
        collection={collection}
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      />
    </div>
  );
};

PostOptions.propTypes = {
  post: PropTypes.object.isRequired,
  collection: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
};

PostOptions.defaultProps = {
  collection: undefined,
};

export default PostOptions;
