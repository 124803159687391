import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { doc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { db, functions } from '../../../firebase/firebase';
import * as gtag from '../../../lib/gtag';
import { useAuth } from '../../../providers/AuthProvider';

const PostOptionsModerate = ({ post, onClose, action }) => {
  const {
    isLoggedIn, isAdmin, uid, user,
  } = useAuth();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const update = async (payload) => {
    try {
      if (post) {
        await updateDoc(doc(db, 'posts', post.id), payload);
      }
    } catch (error) {
      console.log(error, 'error!');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const postLockConfirm = async () => {
    if (isLoggedIn && isAdmin) {
      const lockedDate = new Date();
      await update({
        isLocked: true,
        lockedDate,
        modified: lockedDate,
        lockedBy: {
          uid,
          username: user.username,
        },
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );
      const moderateObj = {
        objectType: 'post',
        objectId: post.id,
        action: 'lock',
      };
      await moderate(moderateObj).catch(() => false);

      enqueueSnackbar('The post has been locked.', { variant: 'success' });
      router.reload();
    } else {
      enqueueSnackbar("You don't have permission to lock posts.", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.lock',
      params: {
        postId: post.id,
        title: post.title,
        uid,
      },
    });
    handleClose();
  };

  const postUnlockConfirm = async () => {
    if (isLoggedIn && isAdmin) {
      const unlockedDate = new Date();
      await update({
        isLocked: false,
        unlockedDate,
        modified: unlockedDate,
        lockedBy: {
          uid,
          username: user.username,
        },
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );
      const moderateObj = {
        objectType: 'post',
        objectId: post.id,
        action: 'unlock',
      };
      await moderate(moderateObj).catch(() => false);

      enqueueSnackbar('The post has been unlocked.', { variant: 'success' });
      router.reload();
    } else {
      enqueueSnackbar("You don't have permission to unlock posts.", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.unlock',
      params: {
        postId: post.id,
        title: post.title,
        uid,
      },
    });
    handleClose();
  };

  const postUnpublishConfirm = async () => {
    if (isLoggedIn && isAdmin) {
      const unpublishedDate = new Date();
      await update({
        isPublished: false,
        unpublishedDate,
        modified: unpublishedDate,
        unpublishedBy: {
          uid,
          username: user.username,
        },
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );
      const moderateObj = {
        objectType: 'post',
        objectId: post.id,
        action: 'unpublish',
      };
      await moderate(moderateObj).catch(() => false);

      enqueueSnackbar('The post has been unpublished.', { variant: 'success' });
      router.reload();
    } else {
      enqueueSnackbar("You don't have permission to unpublish posts.", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.unpublish',
      params: {
        postId: post.id,
        title: post.title,
        uid,
      },
    });
    handleClose();
  };

  const postPublishConfirm = async () => {
    if (isLoggedIn && isAdmin) {
      const restoredDate = new Date();
      await update({
        isPublished: true,
        restoredDate,
        modified: restoredDate,
        restoredBy: {
          uid,
          username: user.username,
        },
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );
      const moderateObj = {
        objectType: 'post',
        objectId: post.id,
        action: 'publish',
      };
      await moderate(moderateObj).catch(() => false);

      enqueueSnackbar('The post has been published.', { variant: 'success' });
      router.reload();
    } else {
      enqueueSnackbar("You don't have permission to publish posts.", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.publish',
      params: {
        postId: post.id,
        title: post.title,
        uid,
      },
    });
    handleClose();
  };

  const handleConfirm = () => {
    switch (action) {
      case 'lock':
        postLockConfirm();
        break;

      case 'unlock':
        postUnlockConfirm();
        break;

      case 'publish':
        postPublishConfirm();
        break;

      case 'unpublish':
        postUnpublishConfirm();
        break;

      default:
        break;
    }
  };

  return (
    <div className="card confirm-dialog">
      <div className="card-content">
        <p className="mb-4 is-font-brand is-size-4">
          Are you sure you wish to
          {' '}
          {action}
          {' '}
          this post?
        </p>
        <div className="buttons modal-footer-buttons">
          <button
            type="button"
            aria-label="Close"
            onClick={() => handleClose()}
            className="button is-light is-medium"
          >
            <span className="icon">
              <Icon path={mdiClose} size={1.25} />
            </span>
          </button>
          <button
            type="button"
            aria-label="OK"
            onClick={handleConfirm}
            className="button is-danger is-medium"
          >
            <span className="icon">
              <Icon path={mdiCheck} size={1.25} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

PostOptionsModerate.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.shape({
      username: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PostOptionsModerate;
