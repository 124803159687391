import { useEffect, useRef, useState } from 'react';
import { IPollOption } from '@/types/Collections/posts';

const PollOption = ({
  option,
  getPollPercentage,
}: {
  option: IPollOption;
  getPollPercentage: (option: IPollOption) => number;
}): JSX.Element => {
  const [divHeight, setDivHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDivHeight(ref?.current?.clientHeight ? ref.current.clientHeight : 0);
  }, [ref]);

  return (
    <div className="poll-vote-count-wrapper">
      <progress
        className="progress is-info poll-vote-count-bar mb-3"
        style={{ height: divHeight + 10 }}
        value={getPollPercentage(option)}
        max="100">
        {getPollPercentage(option)}
      </progress>
      <div className="poll-vote-count" ref={ref}>
        <p className="is-grey-dark">{option.value}</p>
        <p className="is-grey-dark ml-3">{getPollPercentage(option)}%</p>
      </div>
    </div>
  );
};

export default PollOption;
