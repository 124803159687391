import {
  query,
  collection,
  addDoc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  doc,
  orderBy,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  runTransaction,
} from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { ICollection } from '@/types/Collections/collections';
import { Timestamp } from '@/types/Firebase/firebase';

const addPostInteraction = async (
  uid: string,
  postId: string,
  collectionId: string,
): Promise<boolean> =>
  runTransaction(db, async transaction => {
    const ref = doc(db, 'userInteractions', uid, 'postInteractions', postId);
    const postInteraction = await transaction.get(ref);

    if (!postInteraction.exists()) {
      transaction.set(ref, { collections: [collectionId] });
    } else {
      transaction.update(ref, { collections: arrayUnion(collectionId) });
    }
  })
    .then(() => true)
    .catch(() => false);

const removePostInteraction = async (
  uid: string,
  postId: string,
  collectionId: string,
): Promise<boolean> =>
  runTransaction(db, async transaction => {
    const ref = doc(db, 'userInteractions', uid, 'postInteractions', postId);
    const postInteraction = await transaction.get(ref);

    if (!postInteraction.exists) {
      transaction.set(ref, { collections: [] });
    } else {
      transaction.update(ref, { collections: arrayRemove(collectionId) });
    }
  })
    .then(() => true)
    .catch(() => false);

export const postIsAdded = async (
  uid: string,
  postId: string,
  collectionId: string,
): Promise<boolean> => {
  const collectionPost = await getDoc(
    doc(
      db,
      'userCollections',
      uid,
      'collections',
      collectionId,
      'posts',
      postId,
    ),
  );
  if (collectionPost.exists()) {
    return true;
  }
  return false;
};

export const addPost = async (
  uid: string,
  postId: string,
  collectionId: string,
): Promise<boolean> => {
  const added = serverTimestamp() as Timestamp;
  const promises = [];
  promises.push(
    setDoc(
      doc(
        db,
        'userCollections',
        uid,
        'collections',
        collectionId,
        'posts',
        postId,
      ),
      {
        added,
      },
    )
      .then(() => true)
      .catch(err => {
        throw new Error(err);
      }),
  );
  promises.push(addPostInteraction(uid, postId, collectionId));
  return Promise.all(promises).then(() => true);
};

export const removePost = async (
  uid: string,
  postId: string,
  collectionId: string,
): Promise<boolean> => {
  const promises = [];
  promises.push(
    deleteDoc(
      doc(
        db,
        'userCollections',
        uid,
        'collections',
        collectionId,
        'posts',
        postId,
      ),
    )
      .then(() => true)
      .catch(err => {
        throw new Error(err);
      }),
  );
  promises.push(removePostInteraction(uid, postId, collectionId));
  return Promise.all(promises).then(() => true);
};

export const addCollection = async (
  uid: string,
  username: string,
  data: { title: string; isPrivate: boolean },
): Promise<ICollection> => {
  const created = serverTimestamp() as Timestamp;
  const userCollection = {
    title: data.title,
    description: '',
    created,
    isPrivate: data.isPrivate,
    followers: 0,
    author: {
      uid,
      username,
    },
    lastActive: created,
    isDeleted: false,
    postsCount: 0,
  };
  return addDoc(
    collection(db, 'userCollections', uid, 'collections'),
    userCollection,
  ).then(doc => {
    if (doc.id) {
      return {
        ...userCollection,
        id: doc.id,
      };
    }
    throw new Error('Collection not created.');
  });
};

export const addBookmarksCollection = async (
  uid: string,
  username: string,
): Promise<ICollection> => {
  const created = serverTimestamp() as Timestamp;
  const userCollection = {
    title: 'My Bookmarks',
    description: '',
    created,
    isPrivate: true,
    followers: 0,
    author: {
      uid,
      username,
    },
    lastActive: created,
    isDeleted: false,
    postsCount: 0,
  };
  return setDoc(
    doc(db, 'userCollections', uid, 'collections', 'bookmarks'),
    userCollection,
  ).then(() => ({
    ...userCollection,
    id: 'bookmarks',
  }));
};

export const updateCollection = (
  uid: string,
  collectionId: string,
  data: { lastActive: any },
): Promise<boolean> =>
  updateDoc(doc(db, 'userCollections', uid, 'collections', collectionId), data)
    .then(() => true)
    .catch(err => {
      throw new Error(err);
    });

export const getUserCollections = async (
  uid: string,
): Promise<{ collections: ICollection[]; hasBookmarks: boolean }> => {
  const ref = collection(db, 'userCollections', uid, 'collections');
  const constraints = [orderBy('lastActive', 'desc')];
  let hasBookmarks = false;
  let collections = [] as ICollection[];
  return getDocs(query(ref, ...constraints)).then(docs => {
    if (docs.docs.length) {
      collections = docs.docs.map(doc => {
        if (doc.id === 'bookmarks') {
          hasBookmarks = true;
        }
        return { ...doc.data(), id: doc.id } as ICollection;
      });
    }
    return { collections, hasBookmarks };
  });
};
