import { useState } from 'react';
import useInput from '../../../hooks/inputHook';
import Switch from '../../General/Switch';
import BackButton from '../../General/BackButton';
import { IPost } from '../../../types/Collections/posts';
import { ICollection } from '../../../types/Collections/collections';
import { addCollection, addPost } from './api';

const NewCollection = ({
  uid,
  username,
  setIsNew,
  post,
  activeCollections,
  refreshActiveCollections,
  refreshUserCollections,
}: {
  uid: string;
  username: string;
  setIsNew: (isNew: boolean) => void;
  post: IPost;
  activeCollections: string[];
  refreshActiveCollections: (activeCollections: string[]) => void;
  refreshUserCollections: (newCollection: ICollection) => void;
}): JSX.Element => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { value: title, bind: bindTitle, reset: resetTitle } = useInput('');
  const [isPrivate, setIsPrivate] = useState(true);

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsNew(false);
  };

  const handlePrivateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivate(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsWaiting(true);
    const collectionDoc = await addCollection(uid, username, {
      title,
      isPrivate,
    }).catch(err => {
      setError(err.message);
    });
    if (collectionDoc && collectionDoc.id) {
      const postAdded = await addPost(uid, post.id, collectionDoc.id);
      if (postAdded) {
        refreshUserCollections(collectionDoc);
        refreshActiveCollections([...activeCollections, collectionDoc.id]);
      }
      resetTitle();
      setIsWaiting(false);
      setIsNew(false);
    }
  };

  return (
    <div className="collections-list-new">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" htmlFor="titleInput">
            Collection title
            <div className="control is-expanded">
              <input
                id="titleInput"
                type="text"
                className="input"
                placeholder="Give your collection a name"
                {...bindTitle}
              />
            </div>
          </label>
        </div>
        <div className="field is-grouped">
          <div className="control is-expanded">
            <Switch
              name="privacy"
              id="privacy"
              label="Make this collection private?"
              handleChange={handlePrivateChange}
              checked={isPrivate}
            />
          </div>
        </div>
        {error && <p className="help is-danger">{error}</p>}
        <div className="buttons modal-footer-buttons">
          <BackButton handleBack={handleBack} />
          <button
            type="submit"
            className={`button is-primary is-fullwidth${
              isWaiting ? ` is-loading` : ``
            }`}
            disabled={!!error}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCollection;
